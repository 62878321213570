import { useState, useEffect, useContext } from "react"
import { NavLink } from "react-router-dom"
import { AlertsContext } from "../../../../utils/providers/alerts"
import { db } from "../../../../utils/firebase"
import moment from "moment"

// UI imports
import Table from "../../../../components/structure/table/table"
import Badge from "../../../../components/ui/badge/badge"
import Input from "../../../../components/ui/inputs/input"
import Select from "../../../../components/ui/select/select"
import Button from "../../../../components/ui/button/button"

// Days of the week for the selector
const DAYS = {
	monday07: "Monday (07)",
	tuesday08: "Tuesday (08)",
	wednesday09: "Wednesday (09)",
	thursday10: "Thursday (10)",
	friday11: "Friday (11)",
	saturday12: "Saturday (12)",
	sunday13: "Sunday (13)",
	monday14: "Monday (14)",
	tuesday15: "Tuesday (15)",
	wednesday16: "Wednesday (16)",
	thursday17: "Thursday (17)",
	friday18: "Friday (18)",
	saturday19: "Saturday (19)",
	sunday20: "Sunday (20)",
}

// Returns the club hire table for the masters 2025 page
export default function ClubHire({ guests, dataRefresh }) {
	const [searchTerm, setSearchTerm] = useState("")
	const [selectedDay, setSelectedDay] = useState("")
	const [filteredGuests, setFilteredGuests] = useState(guests)

	const { pushAlert } = useContext(AlertsContext)

	// When the search term or selected day changes, filter the guests
	useEffect(() => {
		// If both search term and selected day are empty, show all guests
		if (!searchTerm && !selectedDay) {
			setFilteredGuests(guests)
			return
		}

		// Filter the guests
		const filtered = guests.filter((guest) => {
			const fullName = `${guest.first_name || ""} ${guest.last_name || ""}`.toLowerCase()
			const reference = String(guest.booking_reference || "").toLowerCase()

			// Check if the guest matches the search term
			const matchesSearch = fullName.includes(searchTerm.toLowerCase()) || reference.includes(searchTerm.toLowerCase())

			// Check if the guest has the selected day in their playing days
			const matchesDay = !selectedDay || guest.playing_days.includes(selectedDay)

			return matchesSearch && matchesDay
		})

		// Update the state for the table
		setFilteredGuests(filtered)
	}, [searchTerm, selectedDay, guests])

	// Create a new guests array from the filtered ones where only those who have a club hire day
	const clubHireGuests = filteredGuests.filter((guest) => guest.club_hire)

	// Toggle the club hire for this guest
	const toggleClubHire = async (guest) => {
		try {
			// Update the club hire status in Firestore
			await db.doc(`bookings/${guest.bookingID}/group_names/${guest.guestID}`).set(
				{
					club_hire_picked_up: !guest.club_hire_picked_up,
				},
				{ merge: true }
			)

			// Refresh the data
			dataRefresh()

			// Show a success alert
			pushAlert({
				type: "SUCCESS",
				title: "Club hire status updated",
				body: "The club hire status has been updated for this guest",
			})
		} catch (err) {
			console.error("Error toggling club hire:", err)
		}
	}

	return (
		<>
			<div style={{ display: "flex", gap: "12px", marginBottom: "20px" }}>
				<div style={{ width: "400px" }}>
					<Input
						value={searchTerm}
						onChange={(value) => setSearchTerm(value)}
						placeholder="Search by name or booking reference..."
						type="text"
					/>
				</div>
				<div style={{ width: "200px" }}>
					<Select
						selected={selectedDay}
						placeholder="Select a day"
						options={DAYS}
						onSelect={({ option }) => setSelectedDay(option)}
					/>
				</div>
				<div>
					<Button
						label="Clear"
						type="SECONDARY"
						onClick={() => {
							setSearchTerm("")
							setSelectedDay("")
						}}
					/>
				</div>
			</div>

			<Table
				className="bookings-table"
				headings={["Booking", "Guest Name", "Phone", "Hand", "Picked up", "Returned", "Round(s)"]}>
				{clubHireGuests.length > 0 ? (
					[...clubHireGuests]
						.sort((a, b) => {
							// Order by last name
							return (a.last_name || "").localeCompare(b.last_name || "")
						})
						.map((person) => {
							return (
								<tr key={person.guestID}>
									<td>
										<NavLink to={`/booking/${person.bookingID}`}>{person.booking_reference !== "-" ? person.booking_reference : "Unknown"}</NavLink>
									</td>
									<td>{`${person.first_name || ""} ${person.last_name || ""}`.trim() || "-"}</td>
									<td>{person.phone || "-"}</td>
									<td>{person.club_hire_hand ? person.club_hire_hand.charAt(0).toUpperCase() + person.club_hire_hand.slice(1) : "-"}</td>
									<td>
										<div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
											<div>
												{person.club_hire_picked_up ? (
													<Badge
														label="Yes"
														type="POSITIVE"
													/>
												) : (
													<Badge label="No" />
												)}
											</div>

											{!person.club_hire_picked_up && (
												<Button
													small={true}
													label="Pick up"
													onClick={() => toggleClubHire(person)}
												/>
											)}
										</div>
									</td>
									<td>
										<div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
											<div>
												{person.club_hire_picked_up ? (
													person.club_hire_returned ? (
														<Badge
															label="Yes"
															type="POSITIVE"
														/>
													) : (
														<Badge
															label="No"
															type="AMBER"
														/>
													)
												) : (
													"-"
												)}
											</div>

											{person.club_hire_picked_up && !person.club_hire_returned && (
												<Button
													small={true}
													label="Return"
													onClick={() => toggleClubHire(person)}
												/>
											)}
										</div>
									</td>
									<td>
										<div style={{ display: "flex", flexDirection: "column", gap: "12px", paddingBottom: "12px" }}>
											{person.round_tee_times?.map((round) => (
												<p style={{ margin: "0", lineHeight: "1em" }}>
													{round.course_name} - {moment(round.tee_time?.seconds, "X").format("ddd Do HH:mm")}
												</p>
											))}
										</div>
									</td>
								</tr>
							)
						})
				) : (
					<tr>
						<td
							colSpan="8"
							className="masters-no-data">
							No guests found
						</td>
					</tr>
				)}
			</Table>
		</>
	)
}
