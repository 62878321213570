import { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"

// UI imports
import Table from "../../../../components/structure/table/table"
import Badge from "../../../../components/ui/badge/badge"
import Input from "../../../../components/ui/inputs/input"

// Returns the guests table for the masters 2025 page
export default function Guests({ guests }) {
	const [searchTerm, setSearchTerm] = useState("")
	const [filteredGuests, setFilteredGuests] = useState(guests)

	// When the search term changes, filter the guests
	useEffect(() => {
		// If the search term is empty, show all guests
		if (!searchTerm) {
			setFilteredGuests(guests)
			return
		}

		// Filter the guests
		const filtered = guests.filter((guest) => {
			const fullName = `${guest.first_name || ""} ${guest.last_name || ""}`.toLowerCase()
			const reference = String(guest.booking_reference || "").toLowerCase()
			return fullName.includes(searchTerm.toLowerCase()) || reference.includes(searchTerm.toLowerCase())
		})

		// Update the state for the table
		setFilteredGuests(filtered)
	}, [searchTerm])

	return (
		<>
			<div style={{ width: "400px", marginBottom: "20px" }}>
				<Input
					value={searchTerm}
					onChange={(value) => setSearchTerm(value)}
					placeholder="Search by name or booking reference..."
					type="text"
				/>
			</div>

			<Table
				className="bookings-table"
				headings={["Booking", "Full Name", "Email", "Phone", "Club Hire", "Club Hire Hand", "Transfer Days", "Golf Days", "Masters Days"]}>
				{filteredGuests.length > 0 ? (
					[...filteredGuests]
						.sort((a, b) => {
							// First sort by booking reference
							const refCompare = String(a.reference || "").localeCompare(String(b.reference || ""))
							if (refCompare !== 0) return refCompare

							// If booking refs are the same, sort by last name
							return (a.last_name || "").localeCompare(b.last_name || "")
						})
						.map((person) => {
							return (
								<tr key={person.id}>
									<td>
										<NavLink to={`/booking/${person.bookingID}`}>{person.booking_reference !== "-" ? person.booking_reference : "Unknown"}</NavLink>
									</td>
									<td>{`${person.first_name || ""} ${person.last_name || ""}`.trim() || "-"}</td>
									<td>{person.email || "-"}</td>
									<td>{person.phone || "-"}</td>
									<td>
										<Badge
											type={person.club_hire ? "POSITIVE" : "DEFAULT"}
											label={person.club_hire ? "Yes" : "No"}
										/>
									</td>
									<td>{person.club_hire ? `${person.club_hire_hand.charAt(0).toUpperCase() + person.club_hire_hand.slice(1)}-handed` : "-"}</td>
									<td>{person.transfer_days_string}</td>
									<td>{person.golf_days_string}</td>
									<td>{person.masters_days_string}</td>
									<td className="is-button">
										<div className="condensed-buttons">
											<NavLink to={`/booking/${person.bookingID}`}>View booking</NavLink>
											<NavLink
												to={`/booking/${person.bookingID}`}
												target="_blank">
												View in new tab
											</NavLink>
										</div>
									</td>
								</tr>
							)
						})
				) : (
					<tr>
						<td
							colSpan="9"
							className="masters-no-data">
							No guests found
						</td>
					</tr>
				)}
			</Table>
		</>
	)
}
