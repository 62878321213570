import { useState, useEffect, useContext } from "react"
import { NavLink } from "react-router-dom"
import { AuthContext } from "../../../../utils/providers/auth"
import { AlertsContext } from "../../../../utils/providers/alerts"
import { db } from "../../../../utils/firebase"
import firebase from "firebase"
import moment from "moment"

// UI imports
import Table from "../../../../components/structure/table/table"
import Badge from "../../../../components/ui/badge/badge"
import Input from "../../../../components/ui/inputs/input"
import Select from "../../../../components/ui/select/select"
import Button from "../../../../components/ui/button/button"
import Window from "../../../../components/structure/window/window"

// List of designated hotels people are staying in
const designatedHotels = [
	"Home2Suites by Hilton Columbia Downtown",
	"Holiday Inn - Columbia Downtown",
	"Hilton Garden Inn Columbia/Downtown",
	"Holiday Inn Downtown Columbia",
	"Hilton Garden Inn Columbia/Northeast",
	"Sheraton Columbia Downtown",
	"Econo Lodge, Augusta",
	"Kiawah Island Golf Resort",
	"Wyndham Augusta Wheeler Road",
	"Masters Tickets Only",
]

// Days of the week for the selector
const DAYS = {
	monday: "Monday",
	tuesday: "Tuesday",
	wednesday: "Wednesday",
	thursday: "Thursday",
	friday: "Friday",
	saturday: "Saturday",
	sunday: "Sunday",
}

// Returns the tickets table for the masters 2025 page
export default function Tickets({ guests, tickets }) {
	const [searchTerm, setSearchTerm] = useState("")
	const [selectedDay, setSelectedDay] = useState("")
	const [filteredGuests, setFilteredGuests] = useState(guests)
	const [showAssignModal, setShowAssignModal] = useState(false)
	const [selectedGuest, setSelectedGuest] = useState(null)
	const [ticketCode, setTicketCode] = useState("")
	const [assigningTicket, setAssigningTicket] = useState(false)
	const [selectedTicketDay, setSelectedTicketDay] = useState("")

	const { user } = useContext(AuthContext)
	const { pushAlert } = useContext(AlertsContext)

	// When the search term or selected day changes, filter the guests
	useEffect(() => {
		// If both search term and selected day are empty, show all guests
		if (!searchTerm && !selectedDay) {
			setFilteredGuests(guests)
			return
		}

		// Filter the guests
		const filtered = guests.filter((guest) => {
			const fullName = `${guest.first_name || ""} ${guest.last_name || ""}`.toLowerCase()
			const reference = String(guest.booking_reference || "").toLowerCase()

			// Check if the guest matches the search term
			const matchesSearch = fullName.includes(searchTerm.toLowerCase()) || reference.includes(searchTerm.toLowerCase())

			// Check if the guest has the selected day in their masters days
			const matchesDay = !selectedDay || (guest.masters_days && guest.masters_days[selectedDay])

			return matchesSearch && matchesDay
		})

		// Update the state for the table
		setFilteredGuests(filtered)
	}, [searchTerm, selectedDay, guests])

	// Assign ticket to guest
	const assignTicket = async () => {
		if (!selectedGuest || !ticketCode) {
			pushAlert({
				type: "ERROR",
				title: "Error",
				body: "Please enter a ticket code",
			})
			return
		}

		try {
			setAssigningTicket(true)

			// Check if guest already has an active ticket
			const guestActiveTickets = tickets.filter((t) => t.guestID === selectedGuest.guestID && !t.returned)
			if (guestActiveTickets.length > 0) {
				pushAlert({
					type: "ERROR",
					title: "Error",
					body: "This guest already has an active ticket",
				})
				return
			}

			// Check if ticket code exists and its status
			const existingTicket = tickets.find((t) => t.code === ticketCode.toUpperCase())
			if (existingTicket && !existingTicket.returned) {
				pushAlert({
					type: "ERROR",
					title: "Error",
					body: "This ticket is already assigned and not returned",
				})
				return
			}

			// Create new ticket record
			const newTicket = {
				code: ticketCode.toUpperCase(),
				guestID: selectedGuest.guestID,
				bookingID: selectedGuest.bookingID,
				assigned_by: user.uid,
				assigned_at: firebase.firestore.FieldValue.serverTimestamp(),
				returned: false,
				day: selectedTicketDay || moment().format("dddd").toLowerCase(),
			}

			// Add to tickets collection
			const ticketRef = await db.collection("events/masters_2025/tickets").add(newTicket)

			// Create log entry for ticket assignment
			await db.collection("events/masters_2025/logs").add({
				action: "ticket_assigned",
				ticketID: ticketRef.id,
				ticket_code: ticketCode.toUpperCase(),
				guestID: selectedGuest.guestID,
				bookingID: selectedGuest.bookingID,
				assigned_by: user.uid,
				assigned_at: firebase.firestore.FieldValue.serverTimestamp(),
				details: {
					guestName: `${selectedGuest.first_name || ""} ${selectedGuest.last_name || ""}`.trim(),
					reference: selectedGuest.booking_reference,
					day: newTicket.day,
				},
			})

			// Clear form and close modal
			setTicketCode("")
			setShowAssignModal(false)
			setSelectedGuest(null)
			setSelectedTicketDay("")

			pushAlert({
				type: "SUCCESS",
				title: "Success",
				body: "Ticket assigned successfully",
			})
		} catch (error) {
			console.error("Error assigning ticket:", error)
			pushAlert({
				type: "ERROR",
				title: "Error",
				body: "Failed to assign ticket",
			})
		} finally {
			setAssigningTicket(false)
		}
	}

	// Mark the ticket as returned
	const returnTicket = async (guestID) => {
		try {
			// Find the ticket on the guest which has not yet been returned
			const ticket = tickets.find((t) => t.guestID === guestID && !t.returned)
			if (!ticket) {
				pushAlert({
					type: "ERROR",
					title: "Error",
					body: "No active ticket found",
				})
				return
			}

			// Update the ticket to be returned
			await db.collection("events/masters_2025/tickets").doc(ticket.id).update({
				returned: true,
			})

			// Create log entry for ticket assignment
			await db.collection("events/masters_2025/logs").add({
				action: "ticket_returned",
				ticketID: ticket.id,
				ticketCode: ticket.code,
				guestID: guestID,
				bookingID: ticket.bookingID,
				assignedBy: user.uid,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			})

			pushAlert({
				type: "SUCCESS",
				title: "Success",
				body: "Ticket returned successfully",
			})
		} catch (error) {
			console.error("Error returning ticket:", error)
			pushAlert({
				type: "ERROR",
				title: "Error",
				body: "Failed to return ticket",
			})
		}
	}

	return (
		<>
			<div style={{ display: "flex", gap: "12px", marginBottom: "20px" }}>
				<div style={{ width: "400px" }}>
					<Input
						value={searchTerm}
						onChange={(value) => setSearchTerm(value)}
						placeholder="Search by hotel, client name or booking reference..."
						type="text"
					/>
				</div>
				<div style={{ width: "200px" }}>
					<Select
						selected={selectedDay}
						placeholder="Select a day"
						options={DAYS}
						onSelect={({ option }) => setSelectedDay(option)}
					/>
				</div>
				<div>
					<Button
						label="Clear"
						type="SECONDARY"
						onClick={() => {
							setSearchTerm("")
							setSelectedDay("")
						}}
					/>
				</div>
			</div>

			<small style={{ display: "block", fontSize: "14px", color: "#585858", marginBottom: "12px" }}>Showing {filteredGuests.length} guests</small>

			<Table
				className="bookings-table"
				headings={["Booking", "Guest Name", "Hotel", "Masters Days", "Tickets", ""]}>
				{filteredGuests.length > 0 ? (
					[...filteredGuests]
						.sort((a, b) => {
							// First sort by hotel name
							const hotelA = a.hotel_names[0] || ""
							const hotelB = b.hotel_names[0] || ""
							const hotelCompare = hotelA.localeCompare(hotelB)
							if (hotelCompare !== 0) return hotelCompare

							// Then by last name
							return (a.last_name || "").localeCompare(b.last_name || "")
						})
						.map((person) => {
							// Get all the tickets on this guest
							const personTickets = tickets?.filter((ticket) => ticket.guestID === person.guestID)

							return (
								<tr key={person.guestID}>
									<td>
										<NavLink to={`/booking/${person.bookingID}`}>{person.booking_reference !== "-" ? person.booking_reference : "Unknown"}</NavLink>
									</td>
									<td>{`${person.first_name || ""} ${person.last_name || ""}`.trim() || "-"}</td>
									<td>{person.hotel_names?.find((hotel) => designatedHotels.includes(hotel)) || "-"}</td>
									<td>{person.masters_days_string || "-"}</td>
									<td>
										{personTickets.length > 0 ? (
											<div style={{ display: "flex", gap: "12px" }}>
												{personTickets.map((ticket) => (
													<div key={ticket.id}>
														{ticket.code} ({ticket.day.charAt(0).toUpperCase() + ticket.day.slice(1, 3)}){" "}
														<Badge
															type={ticket.returned ? "POSITIVE" : "AMBER"}
															label={ticket.returned ? "Returned" : "Not Returned"}
														/>
													</div>
												))}
											</div>
										) : (
											"-"
										)}
									</td>
									<td>
										<div style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}>
											<Button
												label="Assign Ticket"
												small
												onClick={() => {
													setSelectedGuest(person)
													setShowAssignModal(true)
												}}
											/>
											<Button
												label="Return Ticket"
												small
												disabled={!personTickets.some((ticket) => !ticket.returned)}
												onClick={() => returnTicket(person.guestID)}
											/>
										</div>
									</td>
								</tr>
							)
						})
				) : (
					<tr>
						<td
							colSpan="8"
							className="masters-no-data">
							No guests found
						</td>
					</tr>
				)}
			</Table>

			{showAssignModal && (
				<Window
					title="Assign Ticket"
					className="slim"
					close={() => {
						setShowAssignModal(false)
						setSelectedGuest(null)
						setTicketCode("")
						setSelectedTicketDay("")
					}}>
					<div style={{ marginBottom: "20px" }}>
						<h3 style={{ marginBottom: "8px", fontWeight: 500 }}>Guest Details</h3>
						<p style={{ marginBottom: "4px", fontSize: "15px" }}>Name: {`${selectedGuest.first_name || ""} ${selectedGuest.last_name || ""}`.trim()}</p>
						<p style={{ marginBottom: "4px", fontSize: "15px" }}>Booking: {selectedGuest.booking_reference}</p>
						<p style={{ marginBottom: "4px", fontSize: "15px" }}>Hotel: {selectedGuest.hotel_names[0]}</p>
					</div>

					<div style={{ marginBottom: "12px" }}>
						<Input
							value={ticketCode}
							onChange={(value) => setTicketCode(value.toUpperCase())}
							placeholder="Enter ticket code..."
							type="text"
						/>
					</div>

					<div style={{ marginBottom: "12px" }}>
						<Select
							selected={selectedTicketDay}
							placeholder={`Today (${moment().format("dddd")})`}
							options={DAYS}
							onSelect={({ option }) => setSelectedTicketDay(option)}
						/>
					</div>

					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							label="Assign Ticket"
							loading={assigningTicket}
							onClick={assignTicket}
						/>
					</div>
				</Window>
			)}
		</>
	)
}
