import { NavLink } from "react-router-dom"

// UI imports
import Table from "../../../../components/structure/table/table"
import Badge from "../../../../components/ui/badge/badge"
import { CheckIcon } from "lucide-react"

// Returns the bookings table for the masters 2025 page
export default function Bookings({ bookings }) {
	return (
		<Table
			className="bookings-table"
			headings={["Agent", "Lead Client", "Group Status", ""]}>
			{bookings.length > 0 ? (
				bookings.map((booking) => (
					<tr key={booking.bookingID}>
						<td>
							{booking.agent?.name}
							{!booking.agent?.name && <small className="no-value">No agent assigned</small>}
						</td>
						<td>
							<NavLink to={`/client/${booking.client.clientID}`}>
								{booking.client?.name}
								{!booking.client?.name && <small className="no-value">No name set</small>}
							</NavLink>
						</td>
						<td>
							<div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
								{booking.complete && (
									<>
										<CheckIcon
											size={19}
											color="#22b229"
										/>

										<div>
											<Badge
												type="POSITIVE"
												label={`${booking.valid_group_names}/${booking.group_size}`}
											/>
										</div>
									</>
								)}

								{!booking.complete && (
									<>
										<XIcon
											size={19}
											color="#d3423d"
										/>

										<div>
											<Badge
												type="NEGATIVE"
												label={`${booking.valid_group_names}/${booking.group_size}`}
											/>
										</div>
									</>
								)}
							</div>
						</td>
						<td className="is-button">
							<div className="condensed-buttons">
								<NavLink to={`/booking/${booking.bookingID}`}>View booking</NavLink>
								<NavLink
									to={`/booking/${booking.bookingID}`}
									target="_blank">
									View in new tab
								</NavLink>
							</div>
						</td>
					</tr>
				))
			) : (
				<tr>
					<td colSpan={9}>
						<small>No bookings found for The Masters 2025 event.</small>
					</td>
				</tr>
			)}
		</Table>
	)
}
