import { useEffect, useState, useContext } from "react"
import { AlertsContext } from "../../../../utils/providers/alerts"
import { db } from "../../../../utils/firebase"
import { NavLink } from "react-router-dom"

// UI imports
import Loading from "../../../../components/app/loading/loading"
import Button from "../../../../components/ui/button/button"
import Input from "../../../../components/ui/inputs/input"
import Table from "../../../../components/structure/table/table"
import Badge from "../../../../components/ui/badge/badge"

// Returns the HTML markup for the golf day tabs
export default function GolfDay({ guests, flag }) {
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)
	const [players, setPlayers] = useState([])
	const [slopeRating, setSlopeRating] = useState(null)
	const [handicapPercentage, setHandicapPercentage] = useState(null)

	// Get the alerts context
	const { pushAlert } = useContext(AlertsContext)

	// On component load
	useEffect(() => {
		try {
			// Fetch the settings pre-sepcified in the database for this golf day
			db.doc(`events/masters_2025/golf_days/${flag}`)
				.get()
				.then((dayDoc) => {
					if (dayDoc.exists) {
						// Pull the data from the document
						const { slope_rating, handicap_percentage } = dayDoc.data()

						// Set the parameters into the state
						setSlopeRating(slope_rating)
						setHandicapPercentage(handicap_percentage)
					}

					// Set the loading to false
					setLoading(false)
				})

			// And also setup a listener for the players
			const playersUnsubscribe = db
				.collectionGroup("group_names")
				.where(`golf_days.${flag}`, "==", true)
				.onSnapshot((snapshot) => {
					// Filter the guests to only include those that have a booking
					const filteredGuests = guests.filter((guest) => guest.booking_reference !== "-")

					// Create an array to store the players
					let localPlayers = []

					// Loop through the snapshot and add the players to the local players array
					snapshot.docs.map((doc) => {
						// Find the guest in the guests array
						const guest = filteredGuests.find((guest) => guest.guestID === doc.id)

						// Return the guest with the golf day data
						localPlayers.push({
							...guest,
						})
					})

					// Remove any which don't have a first/last name
					localPlayers = localPlayers.filter((player) => player.first_name && player.last_name)

					// And then order them by last name, ensuring whitespaces are trimmed
					localPlayers = localPlayers.sort((a, b) => {
						const lastNameA = (a.last_name || "").trim()
						const lastNameB = (b.last_name || "").trim()
						return lastNameA.localeCompare(lastNameB)
					})

					// Set the players to the local players array
					setPlayers(localPlayers)
				})

			// Return the unsubscribe function
			return () => playersUnsubscribe()
		} catch (err) {
			console.error("Error fetching golf day settings:", err)
		} finally {
			setLoading(false)
		}
	}, [])

	// Save the parameters to the database
	const saveParameters = async () => {
		try {
			setSaving(true)

			// Save the parameters to the database
			await db.doc(`events/masters_2025/golf_days/${flag}`).set(
				{
					slope_rating: parseInt(slopeRating),
					handicap_percentage: parseInt(handicapPercentage),
				},
				{ merge: true }
			)

			// Show a success message
			pushAlert({
				type: "SUCCESS",
				title: "Parameters saved successfully",
			})
		} catch (err) {
			console.error("Error saving parameters:", err)
		} finally {
			setSaving(false)
		}
	}

	// If we're still loading the data in, show a loading indicator
	if (loading) return <Loading />

	return (
		<>
			<div style={{ display: "flex", gap: "12px", marginBottom: "20px" }}>
				<div style={{ width: "170px" }}>
					<Input
						showLabel={true}
						value={slopeRating}
						onChange={(value) => setSlopeRating(value)}
						placeholder="Slope rating"
						type="number"
					/>
				</div>
				<div style={{ width: "170px" }}>
					<Input
						showLabel={true}
						value={handicapPercentage}
						onChange={(value) => setHandicapPercentage(value)}
						placeholder="Hcp. percentage"
						type="number"
					/>
				</div>
				<div>
					<Button
						label="Save"
						type="SECONDARY"
						loading={saving}
						loadingText="Saving..."
						onClick={() => saveParameters()}
					/>
				</div>
			</div>

			<small style={{ display: "block", fontSize: "14px", color: "#585858", marginBottom: "12px" }}>Showing {players.length} players</small>

			<Table
				className="bookings-table"
				headings={["Booking", "Guest Name", "Handicap", "Playing Handicap", "Gross Score", "Net Score", "Club Hire", ""]}>
				{players.length > 0 ? (
					players.map((player) => {
						return (
							<tr key={player.guestID}>
								<td>
									<NavLink to={`/booking/${player.bookingID}`}>{player.booking_reference !== "-" ? player.booking_reference : "Unknown"}</NavLink>
								</td>
								<td>{`${player.first_name || ""} ${player.last_name || ""}`.trim() || "-"}</td>
								<td>{player.handicap || "-"}</td>
								<td>{player.playing_handicap || "-"}</td>
								<td>{player.gross_score || "-"}</td>
								<td>{player.net_score || "-"}</td>
								<td>
									{player.club_hire ? (
										<div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
											<div>
												<Badge
													label="Yes"
													type="POSITIVE"
												/>
											</div>
											{player.club_hire_hand.charAt(0).toUpperCase() + player.club_hire_hand.slice(1)}-handed
										</div>
									) : (
										"-"
									)}
								</td>
								<td></td>
							</tr>
						)
					})
				) : (
					<tr>
						<td colSpan="8">No players found for {flag}</td>
					</tr>
				)}
			</Table>
		</>
	)
}
