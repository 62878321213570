import { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"

// UI imports
import Table from "../../../../components/structure/table/table"
import Input from "../../../../components/ui/inputs/input"
import Select from "../../../../components/ui/select/select"
import Button from "../../../../components/ui/button/button"

// List of designated hotels people are staying in
const designatedHotels = [
	"Home2Suites by Hilton Columbia Downtown",
	"Holiday Inn - Columbia Downtown",
	"Hilton Garden Inn Columbia/Downtown",
	"Holiday Inn Downtown Columbia",
	"Hilton Garden Inn Columbia/Northeast",
]

// Days of the week for the selector
const DAYS = {
	monday: "Monday",
	tuesday: "Tuesday",
	wednesday: "Wednesday",
	thursday: "Thursday",
	friday: "Friday",
	saturday: "Saturday",
	sunday: "Sunday",
}

// Returns the transfers table for the masters 2025 page
export default function Transfers({ guests }) {
	const [searchTerm, setSearchTerm] = useState("")
	const [selectedDay, setSelectedDay] = useState("")
	const [filteredGuests, setFilteredGuests] = useState(guests)

	// When the search term or selected day changes, filter the guests
	useEffect(() => {
		// If both search term and selected day are empty, show all guests
		if (!searchTerm && !selectedDay) {
			setFilteredGuests(guests)
			return
		}

		// Filter the guests
		const filtered = guests.filter((guest) => {
			const fullName = `${guest.first_name || ""} ${guest.last_name || ""}`.toLowerCase()
			const reference = String(guest.booking_reference || "").toLowerCase()
			const hotelNames = (guest.hotel_names || []).join(", ").toLowerCase()

			// Check if the guest matches the search term
			const matchesSearch = fullName.includes(searchTerm.toLowerCase()) || reference.includes(searchTerm.toLowerCase()) || hotelNames.includes(searchTerm.toLowerCase())

			// Check if the guest has the selected day in their masters days
			const matchesDay = !selectedDay || (guest.transfer_days && guest.transfer_days[selectedDay])

			return matchesSearch && matchesDay
		})

		// Update the state for the table
		setFilteredGuests(filtered)
	}, [searchTerm, selectedDay, guests])

	// Create an object to track transfer requirements by day and hotel
	const transferRequirements = {
		monday: {},
		tuesday: {},
		wednesday: {},
		thursday: {},
		friday: {},
		saturday: {},
		sunday: {},
	}

	// Initialize each day with all hotels
	Object.keys(transferRequirements).forEach((day) => {
		designatedHotels.forEach((hotel) => {
			transferRequirements[day][hotel] = 0
		})
	})

	// Count transfers needed for each day and hotel
	filteredGuests.forEach((guest) => {
		if (guest.masters_days) {
			guest.hotel_names?.forEach((hotel) => {
				if (designatedHotels.includes(hotel)) {
					Object.entries(guest.masters_days).forEach(([day, attending]) => {
						if (attending) {
							transferRequirements[day][hotel]++
						}
					})
				}
			})
		}
	})

	return (
		<>
			<div style={{ display: "flex", gap: "12px", marginBottom: "20px" }}>
				<div style={{ width: "400px" }}>
					<Input
						value={searchTerm}
						onChange={(value) => setSearchTerm(value)}
						placeholder="Search by hotel, client name or booking reference..."
						type="text"
					/>
				</div>
				<div style={{ width: "200px" }}>
					<Select
						selected={selectedDay}
						placeholder="Select a day"
						options={DAYS}
						onSelect={({ option }) => setSelectedDay(option)}
					/>
				</div>
				<div>
					<Button
						label="Clear"
						type="SECONDARY"
						onClick={() => {
							setSearchTerm("")
							setSelectedDay("")
						}}
					/>
				</div>
			</div>

			<Table
				className="bookings-table"
				headings={["Hotel", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}>
				{designatedHotels
					.filter((hotel) => {
						// Check if any day has tickets needed for this hotel
						return Object.values(transferRequirements).some((day) => day[hotel] > 0)
					})
					.map((hotel) => (
						<tr key={hotel}>
							<td>{hotel}</td>
							<td>{transferRequirements.monday[hotel] || 0}</td>
							<td>{transferRequirements.tuesday[hotel] || 0}</td>
							<td>{transferRequirements.wednesday[hotel] || 0}</td>
							<td>{transferRequirements.thursday[hotel] || 0}</td>
							<td>{transferRequirements.friday[hotel] || 0}</td>
							<td>{transferRequirements.saturday[hotel] || 0}</td>
							<td>{transferRequirements.sunday[hotel] || 0}</td>
						</tr>
					))}
				<tr style={{ fontWeight: 600 }}>
					<td>
						<strong>Total</strong>
					</td>
					<td>{Object.values(transferRequirements.monday).reduce((sum, count) => sum + count, 0)}</td>
					<td>{Object.values(transferRequirements.tuesday).reduce((sum, count) => sum + count, 0)}</td>
					<td>{Object.values(transferRequirements.wednesday).reduce((sum, count) => sum + count, 0)}</td>
					<td>{Object.values(transferRequirements.thursday).reduce((sum, count) => sum + count, 0)}</td>
					<td>{Object.values(transferRequirements.friday).reduce((sum, count) => sum + count, 0)}</td>
					<td>{Object.values(transferRequirements.saturday).reduce((sum, count) => sum + count, 0)}</td>
					<td>{Object.values(transferRequirements.sunday).reduce((sum, count) => sum + count, 0)}</td>
				</tr>
			</Table>

			<br />
			<br />

			<Table
				className="bookings-table"
				headings={["Booking", "Guest Name", "Phone", "Pickup Location", "Transfer Days"]}>
				{filteredGuests.length > 0 ? (
					[...filteredGuests]
						.sort((a, b) => {
							// First sort by designated hotel name
							const hotelA = a.hotel_names?.find((hotel) => designatedHotels.includes(hotel)) || ""
							const hotelB = b.hotel_names?.find((hotel) => designatedHotels.includes(hotel)) || ""
							const hotelCompare = hotelA.localeCompare(hotelB)
							if (hotelCompare !== 0) return hotelCompare

							// Then by last name
							return (a.last_name || "").localeCompare(b.last_name || "")
						})
						.map((person) => {
							return (
								<tr key={person.guestID}>
									<td>
										<NavLink to={`/booking/${person.bookingID}`}>{person.booking_reference !== "-" ? person.booking_reference : "Unknown"}</NavLink>
									</td>
									<td>{`${person.first_name || ""} ${person.last_name || ""}`.trim() || "-"}</td>
									<td>{person.phone || "-"}</td>
									<td>{person.hotel_names?.find((hotel) => designatedHotels.includes(hotel)) || "-"}</td>
									<td>{person.transfer_days_string || "-"}</td>
								</tr>
							)
						})
				) : (
					<tr>
						<td
							colSpan="8"
							className="masters-no-data">
							No guests found
						</td>
					</tr>
				)}
			</Table>
		</>
	)
}
