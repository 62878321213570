import { useState, useEffect } from "react"
import { db } from "../../../utils/firebase"
import moment from "moment"

// UI imports
import Navigation from "../../../components/ui/navigation/navigation"
import Title from "../../../components/structure/title/title"
import Tile from "../../../components/structure/tile/tile"
import Loading from "../../../components/app/loading/loading"

// Tab imports
import Bookings from "./_tabs/bookings"
import Guests from "./_tabs/guests"
import Hotels from "./_tabs/hotels"
import Tickets from "./_tabs/tickets"
import ClubHire from "./_tabs/club-hire"
import Transfers from "./_tabs/transfers"
import GolfDay from "./_tabs/golf-day"

// Used for The Masters 2025
export default function Masters2025() {
	const [loading, setLoading] = useState(true)
	const [activeTab, setActiveTab] = useState("BOOKINGS")
	const [bookings, setBookings] = useState([])
	const [guests, setGuests] = useState([])
	const [tickets, setTickets] = useState([])

	// Fetching the event information, to be called by other tabs as a refresh
	const fetchEventInformation = async () => {
		try {
			// Find all the bookings where we have the masters tag
			const bookingsSnapshot = await db.collection("bookings").where("event_tag", "==", "the_masters_2025").where("removed", "==", false).get()

			// And create an array of all the bookings and their data points
			const bookingsData = bookingsSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}))

			// Fetch group names stats for each booking
			const bookingDetails = bookingsData.map(async (booking) => {
				// Get the agent information
				const agent = await db
					.doc(`users/${booking.agent}`)
					.get()
					.then((agentDoc) => {
						return {
							...agentDoc.data(),
							agentID: agentDoc.id,
							name: `${agentDoc.data().first_name} ${agentDoc.data().last_name}`,
						}
					})

				// Get the client information
				const client = await db
					.doc(`clients/${booking.client}`)
					.get()
					.then((clientDoc) => {
						return {
							...clientDoc.data(),
							clientID: clientDoc.id,
							name: `${clientDoc.data().first_name} ${clientDoc.data().last_name}`,
						}
					})

				// Get each of the hotels on the booking
				const hotelSnapshot = await db.collection(`bookings/${booking.id}/hotels`).get()

				// Create an object to store the hotels
				const hotelsData = []

				// If there are any hotels on the booking
				if (!hotelSnapshot.empty) {
					const hotelDocs = []

					// For each hotel on the booking
					for (const doc of hotelSnapshot.docs) {
						const hotelData = doc.data()

						// Fetch the hotel details from the main hotels collection
						if (hotelData.resortID) {
							const hotelDoc = await db.doc(`hotels/${hotelData.resortID}`).get()
							if (hotelDoc.exists) {
								hotelDocs.push({
									...hotelData,
									hotelID: doc.id,
									hotel_name: hotelDoc.data().name?.trim(),
								})
							}
						}
					}

					// Add the hotels to the booking
					hotelsData.push(...hotelDocs)
				}

				// Get an array of each of the hotel names
				const hotelNames = hotelsData.map((hotel) => hotel.hotel_name)

				// Get all the rounds on the booking
				const roundSnapshot = await db.collection(`bookings/${booking.id}/rounds`).orderBy("tee_time", "asc").get()

				// Create an object to hold all rounds by booking
				const roundsData = []
				const roundTeeTimes = []
				const playingDays = []

				// If there are any rounds on the booking
				if (!roundSnapshot.empty) {
					const roundDocs = []

					// Fetch each round data from the main courses collection
					for (const doc of roundSnapshot.docs) {
						const roundData = doc.data()

						// Fetch the course data from the main courses collection
						if (roundData.courseID) {
							const courseDoc = await db.doc(`courses/${roundData.courseID}`).get()
							if (courseDoc.exists) {
								roundDocs.push({
									...roundData,
									roundID: doc.id,
									course_name: courseDoc.data().name,
								})

								// Aslong as this isn't a US Masters Day Ticket, add the round to the roundTeeTimes array
								if (courseDoc.data().name !== "US Masters Day Ticket") {
									roundTeeTimes.push({
										...roundData,
										roundID: doc.id,
										course_name: courseDoc.data().name,
										tee_time: roundData.tee_time,
									})

									// Add the playing day to the playingDays array
									playingDays.push(moment(roundData.tee_time?.seconds, "X").format("ddddDD")?.toLowerCase())
								}
							}
						}
					}

					// Add the rounds to the booking
					roundsData.push(...roundDocs)
				}

				// Get all the course names
				const courseNames = roundsData.map((round) => round.course_name)

				// Get the total group sizes for this booking
				const golfers = parseInt(booking.golfers || 0)
				const nonGolfers = parseInt(booking.non_golfers || 0)
				const groupSize = golfers + nonGolfers

				// Get group names from the subcollection
				const groupNamesSnapshot = await db.collection(`bookings/${booking.id}/group_names`).get()
				const groupNames = groupNamesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))

				// Count only entries with at least a first name or last name
				const validGroupNames = groupNames.filter((person) => person.first_name || person.last_name).length

				// Map the group names and add booking reference
				const condensedNames = groupNames.map((doc) => {
					let clubHireStatus = {}

					// Extract club hire status if it exists
					if (doc.club_hire_status) {
						Object.keys(doc.club_hire_status).forEach((dateKey) => {
							const statusObj = doc.club_hire_status[dateKey]

							// Add to the status data
							clubHireStatus[`${doc.id}_${dateKey}`] = {
								sourced: statusObj.sourced || false,
								picked_up: statusObj.picked_up || false,
								returned: statusObj.returned || false,
							}
						})
					}

					// Format transfer days
					const transferDays = doc.transfer_days
						? Object.entries(doc.transfer_days)
								.filter(([_, needed]) => needed)
								.sort(([a], [b]) => {
									const daysOrder = { monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6, sunday: 7 }
									return daysOrder[a.toLowerCase()] - daysOrder[b.toLowerCase()]
								})
								.map(([day]) => day.charAt(0).toUpperCase() + day.slice(1))
								.join(", ") || "-"
						: "-"

					// Format golf days
					const golfDays = doc.golf_days
						? Object.entries(doc.golf_days)
								.filter(([_, playing]) => playing)
								.map(([day]) => {
									switch (day) {
										case "friday_columbia":
											return "Friday - Columbia"
										case "friday_lexington":
											return "Friday - Lexington"
										case "saturday_windermere":
											return "Saturday - Windermere"
										default:
											return day
									}
								})
								.join(", ") || "-"
						: "-"

					// Format masters days
					const mastersDays = doc.masters_days
						? Object.entries(doc.masters_days)
								.filter(([_, attending]) => attending)
								.sort(([a], [b]) => {
									const daysOrder = { monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6, sunday: 7 }
									return daysOrder[a.toLowerCase()] - daysOrder[b.toLowerCase()]
								})
								.map(([day]) => day.charAt(0).toUpperCase() + day.slice(1))
								.join(", ") || "-"
						: "-"

					return {
						guestID: doc.id,
						club_hire_status: clubHireStatus,
						bookingID: booking.id,
						booking: booking,
						booking_reference: booking.reference,
						transfer_days_string: transferDays,
						golf_days_string: golfDays,
						masters_days_string: mastersDays,
						hotels: hotelsData,
						hotel_names: hotelNames,
						rounds: roundsData,
						course_names: courseNames,
						round_tee_times: roundTeeTimes,
						playing_days: playingDays,
						...doc,
					}
				})

				return {
					bookingID: booking.id,
					reference: booking.reference,
					client,
					agent,
					golfers,
					non_golfers: nonGolfers,
					group_size: groupSize,
					group_names: condensedNames,
					valid_group_names: validGroupNames,
					hotels: hotelsData,
					hotel_names: hotelNames,
					rounds: roundsData,
					course_names: courseNames,
					complete: validGroupNames >= groupSize && groupSize > 0,
					incomplete: groupSize > 0 && validGroupNames < groupSize,
					no_group_size: groupSize === 0,
				}
			})

			// Wait for all stats to be fetched
			const bookingDetailsResults = await Promise.all(bookingDetails)

			// Get an array of all the group names from the bookings
			const allGroupNames = bookingDetailsResults.map((booking) => booking.group_names).flat()

			// Set the state with the values
			setBookings(bookingDetailsResults)
			setGuests(allGroupNames)
		} catch (err) {
			console.error("Error fetching bookings:", err)
		} finally {
			setLoading(false)
		}
	}

	// On component mount
	useEffect(() => {
		// Fetch the event information
		fetchEventInformation()

		// Then setup a listener on the tickets collection
		const mastersTicketsSnapshot = db.collection("events/masters_2025/tickets").onSnapshot((snapshot) => {
			const ticketsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
			setTickets(ticketsData)
		})

		// Return a cleanup function
		return () => mastersTicketsSnapshot()
	}, [])

	return (
		<Tile>
			<Title>
				<h1>The Masters 2025</h1>
			</Title>

			<Navigation
				links={[
					{
						id: "BOOKINGS",
						label: "Bookings",
					},
					{
						id: "GUESTS",
						label: "Guests",
					},
					{
						id: "HOTELS",
						label: "Hotels",
					},
					{
						id: "TICKETS",
						label: "Tickets",
					},
					{
						id: "CLUB_HIRE",
						label: "Club Hire",
					},
					{
						id: "TRANSFERS",
						label: "Transfers",
					},
					{
						id: "FRIDAY_COLUMBIA",
						label: "Friday - Columbia",
					},
					{
						id: "FRIDAY_LEXINGTON",
						label: "Friday - Lexington",
					},
					{
						id: "SATURDAY_WINDERMERE",
						label: "Saturday - Windermere",
					},
				]}
				active={activeTab}
				onClick={(tabID) => setActiveTab(tabID)}
			/>

			{/* Default loading state while everything loads */}
			{loading && <Loading />}

			{/* Then we'll display the related tab */}
			{!loading && (
				<>
					{activeTab === "BOOKINGS" && <Bookings bookings={bookings} />}
					{activeTab === "GUESTS" && <Guests guests={guests} />}
					{activeTab === "HOTELS" && (
						<Hotels
							guests={guests}
							tickets={tickets}
						/>
					)}
					{activeTab === "TICKETS" && (
						<Tickets
							guests={guests}
							tickets={tickets}
						/>
					)}
					{activeTab === "CLUB_HIRE" && (
						<ClubHire
							guests={guests}
							dataRefresh={fetchEventInformation}
						/>
					)}
					{activeTab === "TRANSFERS" && <Transfers guests={[...guests.filter((guest) => guest.transfer_days && Object.values(guest.transfer_days).some((needed) => needed))]} />}
					{activeTab === "FRIDAY_COLUMBIA" && (
						<GolfDay
							guests={guests}
							flag="friday_columbia"
						/>
					)}
					{activeTab === "FRIDAY_LEXINGTON" && (
						<GolfDay
							guests={guests}
							flag="friday_lexington"
						/>
					)}
					{activeTab === "SATURDAY_WINDERMERE" && (
						<GolfDay
							guests={guests}
							flag="saturday_windermere"
						/>
					)}
				</>
			)}
		</Tile>
	)
}
